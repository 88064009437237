import React from "react";
import {
  CreateGuesser,
  EditGuesser,
  ListGuesser,
  FieldGuesser,
} from "@api-platform/admin";
import {
  TextField,
  BooleanField,
  BooleanInput,
  ReferenceInput,
  AutocompleteInput,
  TextInput,
} from "react-admin";

const tagFilters = [<TextInput label="Search" source="label" alwaysOn />];

export const TagCreate = (props) => (
  <CreateGuesser {...props}>
    <TextInput source={"label"} />
    <BooleanInput source={"isSpecial"} />
    <ReferenceInput source="domain" reference="domains" label="Domain">
      <AutocompleteInput
        optionText="name"
        filterToQuery={(searchText) => ({ searchtext: searchText })}
      />
    </ReferenceInput>
  </CreateGuesser>
);

export const TagEdit = (props) => (
  <EditGuesser {...props}>
    <TextInput source={"label"} />
    <BooleanInput source={"isSpecial"} />
    <ReferenceInput source="domain" reference="domains" label="Domain">
      <AutocompleteInput
        optionText="name"
        filterToQuery={(searchText) => ({ searchtext: searchText })}
      />
    </ReferenceInput>
  </EditGuesser>
);

export const TagList = (props) => (
  <ListGuesser {...props} filters={tagFilters}>
    <TextField source={"label"} />
    <BooleanField source={"isSpecial"} />
    <FieldGuesser source={"domain"} />
  </ListGuesser>
);
