import React from "react";
import {
  CreateGuesser,
  EditGuesser,
  ListGuesser,
  FieldGuesser,
} from "@api-platform/admin";
import { ReferenceInput, AutocompleteInput } from "react-admin";

export const AvatarDomainTagCreate = (props) => (
  <CreateGuesser {...props}>
    <ReferenceInput source="avatar" reference="avatars" label="Avatar">
      <AutocompleteInput
        optionText="nick"
        filterToQuery={(searchText) => ({ nick: searchText })}
      />
    </ReferenceInput>
    <ReferenceInput source="domain" reference="domains" label="Domain">
      <AutocompleteInput
        optionText="name"
        filterToQuery={(searchText) => ({ searchtext: searchText })}
      />
    </ReferenceInput>
    <ReferenceInput source="tag" reference="tags" label="Tag">
      <AutocompleteInput
        optionText="label"
        filterToQuery={(searchText) => ({ label: searchText })}
      />
    </ReferenceInput>
  </CreateGuesser>
);

export const AvatarDomainTagEdit = (props) => (
  <EditGuesser {...props}>
    <ReferenceInput source="avatar" reference="avatars" label="Avatar">
      <AutocompleteInput
        optionText="nick"
        filterToQuery={(searchText) => ({ nick: searchText })}
      />
    </ReferenceInput>
    <ReferenceInput source="domain" reference="domains" label="Domain">
      <AutocompleteInput
        optionText="name"
        filterToQuery={(searchText) => ({ searchtext: searchText })}
      />
    </ReferenceInput>
    <ReferenceInput source="tag" reference="tags" label="Tag">
      <AutocompleteInput
        optionText="label"
        filterToQuery={(searchText) => ({ label: searchText })}
      />
    </ReferenceInput>
  </EditGuesser>
);

export const AvatarDomainTagList = (props) => (
  <ListGuesser {...props}>
    <FieldGuesser source={"avatar"} />
    <FieldGuesser source={"domain"} />
    <FieldGuesser source={"tag"} />
  </ListGuesser>
);
