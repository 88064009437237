import React from "react";
import {
  CreateGuesser,
  EditGuesser,
  InputGuesser,
  ListGuesser,
  FieldGuesser,
  ShowGuesser,
} from "@api-platform/admin";
import {
  ImageInput,
  ImageField,
  Datagrid,
  ArrayField,
  TextField,
  ReferenceArrayInput,
  SelectArrayInput,
  useRecordContext,
} from "react-admin";
import { useController, useWatch } from "react-hook-form";
import Button from "@mui/material/Button";

const UploadedImage = () => {
  const record = useRecordContext();
  const image = useController({ name: "mediaObject" });
  const watchImage = useWatch({ name: "mediaObject" });

  return !!record.image && watchImage !== null ? (
    <>
      <ImageField source="image" title="title" />
      <Button onClick={() => image.field.onChange(null)}>Remove image</Button>
    </>
  ) : null;
};

export const AvatarCreate = (props) => (
  <CreateGuesser {...props}>
    <InputGuesser source={"nick"} />
    <InputGuesser source={"bio"} />
    <InputGuesser source={"twitter"} />
    <InputGuesser source={"linkedin"} />
    <InputGuesser source={"instagram"} />
    <InputGuesser source={"facebook"} />
    <ImageField source="image" title="title" />
    <ImageInput source="mediaObject" accept="image/*">
      <ImageField source="image" title="title" />
    </ImageInput>
  </CreateGuesser>
);

export const AvatarEdit = (props) => (
  <EditGuesser {...props}>
    <InputGuesser source={"nick"} />
    <InputGuesser source={"bio"} />
    <InputGuesser source={"twitter"} />
    <InputGuesser source={"linkedin"} />
    <InputGuesser source={"instagram"} />
    <InputGuesser source={"facebook"} />
    <UploadedImage />
    <ImageInput source="mediaObject" accept="image/*">
      <ImageField source="image" title="title" />
    </ImageInput>
    <ReferenceArrayInput source="tag_ids" reference="tags">
      <SelectArrayInput optionText={"label"} />
    </ReferenceArrayInput>
  </EditGuesser>
);

export const AvatarList = (props) => (
  <ListGuesser {...props}>
    <FieldGuesser source={"nick"} />
    <ImageField source="image" title="title" />
  </ListGuesser>
);

export const AvatarShow = (props) => (
  <ShowGuesser>
    <FieldGuesser source={"nick"} />
    <ImageField source="image" title="title" />
    <FieldGuesser source={"bio"} />
    <FieldGuesser source={"twitter"} />
    <FieldGuesser source={"linkedin"} />
    <FieldGuesser source={"instagram"} />
    <FieldGuesser source={"facebook"} />
    <ArrayField source="avatarTags">
      <Datagrid isRowSelectable={false} bulkActionButtons={null}>
        <TextField source="tag.label" label="Label" />
      </Datagrid>
    </ArrayField>
  </ShowGuesser>
);
