import React from "react";
import {
  CreateGuesser,
  EditGuesser,
  InputGuesser,
  ListGuesser,
  FieldGuesser,
  ShowGuesser,
} from "@api-platform/admin";
import { ReferenceInput, AutocompleteInput } from "react-admin";

export const SouvenirCreate = (props) => (
  <CreateGuesser {...props}>
    <InputGuesser source={"title"} />
    <InputGuesser source={"url"} />
    <InputGuesser source={"content"} />
    <ReferenceInput source="avatar" reference="avatars">
      <AutocompleteInput label="Avatar" optionText={"nick"} />
    </ReferenceInput>
    <ReferenceInput source="domain" reference="domains">
      <AutocompleteInput label="Domain" optionText={"name"} />
    </ReferenceInput>
    <InputGuesser source={"createdAt"} />
  </CreateGuesser>
);

export const SouvenirEdit = (props) => (
  <EditGuesser {...props}>
    <InputGuesser source={"title"} />
    <InputGuesser source={"url"} />
    <InputGuesser source={"content"} />
    <ReferenceInput source="avatar" reference="avatars">
      <AutocompleteInput label="Avatar" optionText={"nick"} />
    </ReferenceInput>
    <ReferenceInput source="domain" reference="domains">
      <AutocompleteInput label="Domain" optionText={"name"} />
    </ReferenceInput>
  </EditGuesser>
);

export const SouvenirList = (props) => (
  <ListGuesser {...props}>
    <FieldGuesser source={"title"} />
    <FieldGuesser source={"content"} />
    <FieldGuesser source={"url"} />
    <FieldGuesser source={"avatar"} />
    <FieldGuesser source={"domain"} />
    <FieldGuesser source={"createdAt"} />
  </ListGuesser>
);

export const SouvenirShow = (props) => (
  <ShowGuesser {...props}>
    <FieldGuesser source={"title"} />
    <FieldGuesser source={"url"} />
    <FieldGuesser source={"content"} />
    <FieldGuesser source={"domain"} />
    <FieldGuesser source={"avatar"} />
    <FieldGuesser source={"createdAt"} />
  </ShowGuesser>
);
