import React from "react";
// import Head from "next/head";
import { Navigate, Route } from "react-router-dom";
import {
  fetchHydra as baseFetchHydra,
  hydraDataProvider as baseHydraDataProvider,
  useIntrospection,
} from "@api-platform/admin";
import { parseHydraDocumentation } from "@api-platform/api-doc-parser";

let ENTRYPOINT = process.env.REACT_APP_API_URL;

const getHeaders = () =>
  localStorage.getItem("token")
    ? {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      }
    : {};

const fetchHydra = (url, options = {}) =>
  baseFetchHydra(url, {
    ...options,
    headers: getHeaders,
  });

const RedirectToLogin = () => {
  const introspect = useIntrospection();

  if (localStorage.getItem("token")) {
    introspect();
    return <></>;
  }
  return <Navigate to="/login" />;
};

const apiDocumentationParser = async () => {
  try {
    return await parseHydraDocumentation(ENTRYPOINT, { headers: getHeaders });
  } catch (result) {
    const { api, response, status } = result;
    if (status !== 401 || !response) {
      throw result;
    }

    // Prevent infinite loop if the token is expired
    localStorage.removeItem("token");

    return {
      api,
      response,
      status,
      customRoutes: [<Route key="/" path="/" component={RedirectToLogin} />],
    };
  }
};
const dataProvider = baseHydraDataProvider({
  entrypoint: ENTRYPOINT,
  httpClient: fetchHydra,
  apiDocumentationParser,
});

const resourceWithImage = ["domains", "avatars", "services"];

const CustomDataProvider = {
  ...dataProvider,
  getOne: async (resource, params) => {
    console.log(resource, params);

    const response = await dataProvider.getOne(resource, {
      id: params.id,
      meta: params.meta,
    });

    if (resource === "users") {
      response.data.avatar_ids = response.data.avatars.map((a) => a["@id"]);
    }

    if (resource === "avatars") {
      response.data.tag_ids = response.data.avatarTags.map((a) => a["@id"]);
      console.log(response.data);
    }

    return response;
  },
  update: async (resource, params) => {
    if (resource === "users") {
      params.data.avatars = params.data.avatar_ids;
      delete params.data.avatar_ids;

      //console.log(params);
    }

    if (resource === "avatars") {
      params.data.avatarTags = params.data.tag_ids;
      delete params.data.tag_ids;

      //console.log(params);
    }

    if (!resourceWithImage.includes(resource)) {
      params.data.mediaObject = null;
      return dataProvider.update(resource, params);
    }

    const file = params?.data?.mediaObject?.rawFile;

    if (!file) {
      params.data.mediaObject = null;
      return dataProvider.update(resource, params);
    }

    const mediaObject = await dataProvider.create("media_objects", {
      data: { file: file, extraInformation: { hasFileField: true } },
    });

    params.data.mediaObject = mediaObject.data.id;

    return dataProvider.update(resource, {
      id: params.data.id,
      data: params.data,
    });
  },
  create: async (resource, params) => {
    if (!resourceWithImage.includes(resource)) {
      params.data.mediaObject = null;
      return dataProvider.create(resource, params);
    }

    const file = params?.data?.mediaObject?.rawFile;

    if (!file) {
      return dataProvider.create(resource, params);
    }

    const mediaObject = await dataProvider.create("media_objects", {
      data: { file: file, extraInformation: { hasFileField: true } },
    });

    params.data.mediaObject = mediaObject.data.id;

    return dataProvider.create(resource, {
      id: params.data.id,
      data: params.data,
    });
  },
  getUser: async () => {
    const headers = getHeaders();
    return await fetch(ENTRYPOINT + "/me", {
      method: "GET",
      headers: headers,
    })
      .then((response) => response.json())
      .then((data) => data)
      .catch((err) => console.error(err));
  },
  getNearbyDomains: async (lonLat) => {
    const headers = getHeaders();
    return await fetch(ENTRYPOINT + "/domains/city?lonLat=" + lonLat, {
      method: "GET",
      headers: headers,
    })
      .then((response) => response.json())
      .then((data) => data)
      .catch((err) => console.error(err));
  },
};

export default CustomDataProvider;
