import React, { useEffect, useState } from "react";

import {
    InputGuesser,
    ListGuesser,
    FieldGuesser,
    EditGuesser,
} from "@api-platform/admin";
import {
    Create,
    NumberInput,
    SimpleForm,
    useCreate,
    useRedirect,
    SelectInput,
    required,
    minValue,
    maxValue,
    ReferenceField,
    TextField,
} from "react-admin";
import { useFormContext } from "react-hook-form";

import {
    Button,
    Grid,
    CircularProgress} from "@mui/material";

import { MapFieldAp } from "../../Field/Map/MapFieldAp";

export const WifiList = (props) => (
    <ListGuesser {...props}>
        <FieldGuesser source={"ssid"} />
        {/* <FieldGuesser source={"geolocation"} /> */}
        <FieldGuesser source={"n"} />
        <FieldGuesser source={"mp"} />
        <ReferenceField label="Domain" source="domain" reference="domains">
            <TextField source="name" />
        </ReferenceField>
    </ListGuesser>
);

const GeolocationApCreate = () => {
    // const [domainId, setDomainId] = useState(null);
    const [domain, setDomain] = useState(null);
    const { setValue, getValues } = useFormContext();
    const [loading, setLoading] = useState(false);
    const [searched, setSearched] = useState(false);

    const searchDomain = async () => {
        const { domainId } = getValues();
        if (domainId) {
            setLoading(true);
            setSearched(true);
            try {
                // CustomDataProvider.getOne('domains', { id: domainId });
                const response = await fetch(`${process.env.REACT_APP_API_URL}/domains/${domainId}`,
                    {
                        method: 'GET',
                        headers: {
                            'Accept': 'application/json',
                            'Authorization': `Bearer ${localStorage.getItem("token")}`
                        }
                    });
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                const res = await response.json();
                console.log({ domainResponse: res })
                setDomain(res);
                setLoading(false);
            } catch (e) {
                setDomain(null);
                setLoading(false);
                console.error(e);
            }
        }
    }

    const renderMap = () => {
        if (loading) {
            return <CircularProgress />;
        }
        if (searched) {
            if (domain) {
                return <MapFieldAp
                    source={"geolocation"}
                    domain={domain}
                    style={{ height: "30rem", width: "40rem" }}
                />;
            } else {
                return <div>No se ha encontrado el dominio</div>;
            }
        }
        return null;
    }

    return (
        <>
            <div>
                <div>
                    {`Dominio seleccionado: ${domain?.id ?? 'No seleccionado'}`}
                </div>
                <NumberInput source={"domainId"} label="Id del dominio" />
                <Button variant="contained" onClick={searchDomain}>
                    Buscar dominio
                </Button>
            </div>
            <div>
                {renderMap()}
            </div>
        </>
    )
}

export const WifiCreate = (props) => {
    const redirect = useRedirect();
    const [create] = useCreate();
    const save = React.useCallback(
        async (values) => {
            try {
                const n = values.n ? values.n : null;
                await create("wifis", { data: { ...values, n } }, { returnPromise: true });
                redirect("/wifis");
            } catch (error) {
                console.log(error);
            }
        },
        [create]
    );

    const validateSsid = [required()];
    const validateMp = [minValue(-90), maxValue(-20)];
    return (
        <Create {...props}>
            <SimpleForm onSubmit={save}>
                <Grid container spacing={6}>
                    <Grid item md={3}>
                        <InputGuesser source={"ssid"} fullWidth={true} label="SSID" validate={validateSsid} />
                        <SelectInput source={"n"} fullWidth={true} label="Environment type" emptyValue={null} choices={[
                            { id: 2, name: 'Free space' },
                            { id: 2.7, name: 'Urban area' },
                            { id: 5, name: 'Shadowed urban area' },
                            { id: 1.6, name: 'In building line of sight' },
                            { id: 4, name: 'Obstructed in-building' },
                            { id: 3, name: 'Obstructed in factories' },
                        ]} />
                        <InputGuesser source={"mp"} fullWidth={true} label="Measure power" validate={validateMp}/>
                    </Grid>
                    <Grid item md={8}>
                        <GeolocationApCreate />
                    </Grid>
                </Grid>
            </SimpleForm>
        </Create>
    );
};

const GeolocationApEdit = () => {
    const [domain, setDomain] = useState(null);
    const { setValue, getValues } = useFormContext();
    const [loading, setLoading] = useState(true);
    const [searched, setSearched] = useState(false);

    useEffect(() => {
        const { domain } = getValues();
        const domainId = domain?.split('/')?.pop() ?? null;
        setValue('domainId', domainId);
        if (domainId) {
            setSearched(true);
            async function fetchData() {
                try {
                    const response = await fetch(`${process.env.REACT_APP_API_URL}/domains/${domainId}`,
                        {
                            method: 'GET',
                            headers: {
                                'Accept': 'application/json',
                                'Authorization': `Bearer ${localStorage.getItem("token")}`
                            }
                        });
                    if (!response.ok) {
                        throw Error(response.statusText);
                    }
                    const res = await response.json();
                    console.log({ domainResponse: res })
                    setValue('domain', `api/domains/${domainId}`);
                    setDomain(res);
                    setLoading(false);
                } catch (e) {
                    setLoading(false);
                    console.error(e);
                }
            }
            fetchData();
        }
        setLoading(false);
    }, [])

    const searchDomain = async () => {
        const { domainId } = getValues();
        if (domainId) {
            setLoading(true);
            setSearched(true);
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/domains/${domainId}`,
                    {
                        method: 'GET',
                        headers: {
                            'Accept': 'application/json',
                            'Authorization': `Bearer ${localStorage.getItem("token")}`
                        }
                    });
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                const res = await response.json();
                console.log({ domainResponse: res })
                setDomain(res);
                setLoading(false);
            } catch (e) {
                setDomain(null);
                setLoading(false);
                console.error(e);
            }
        }
    }

    const renderMap = () => {
        if (loading) {
            return <CircularProgress />
        }
        if (searched) {
            if (domain) {
                return <MapFieldAp
                    source={"geolocation"}
                    domain={domain}
                    style={{ height: "30rem", width: "40rem" }}
                />
            } else {
                return <div>No se ha encontrado el dominio</div>
            }
        }
        return null
    }

    const unselectDomain = () => {
        setDomain(null);
        setValue('domainId', null);
        setValue('geolocation', null);
        setValue('domain', null);
    }

    return (
        <Grid container>
            <Grid item>
                <div>
                    {`Dominio seleccionado: ${domain?.id ?? 'No seleccionado'}`}
                </div>
                <div>
                    <NumberInput source={"domainId"} label="Id del dominio" />
                </div>
                <div>
                    <Button variant="contained" onClick={searchDomain}>
                        Buscar dominio
                    </Button>
                    <Button variant="contained" onClick={unselectDomain}>
                        Deseleccionar dominio
                    </Button>
                </div>
                {renderMap()}
            </Grid>
        </Grid>
    )
}

export const WifiEdit = (props) => {
    const validateSsid = [required()];
    const validateMp = [minValue(-90), maxValue(-20)];
    return (
        <EditGuesser {...props}>
            <Grid container spacing={6}>
                <Grid item md={3}>
                    <InputGuesser source={"ssid"} fullWidth={true} label="SSID" validate={validateSsid} />
                    <SelectInput source={"n"} fullWidth={true} label="Environment type" emptyValue={null} choices={[
                        { id: 2, name: 'Free space' },
                        { id: 2.7, name: 'Urban area' },
                        { id: 5, name: 'Shadowed urban area' },
                        { id: 1.6, name: 'In building line of sight' },
                        { id: 4, name: 'Obstructed in-building' },
                        { id: 3, name: 'Obstructed in factories' },
                    ]} />
                    <InputGuesser source={"mp"} fullWidth={true} label="Measure power" validate={validateMp} />
                </Grid>
                <Grid item md={8}>
                    <GeolocationApEdit />
                </Grid>
            </Grid>
        </EditGuesser>)
};