import React from "react";
import Button from "@mui/material/Button";
import {useFormContext} from "react-hook-form";
import {
    CreateGuesser,
    EditGuesser,
    InputGuesser,
    ListGuesser,
    FieldGuesser,
    ShowGuesser,
} from "@api-platform/admin";
import {
    SelectArrayInput,
    Datagrid,
    ImageField,
    ArrayField,
    TextField,
    ReferenceArrayInput,
    usePermissions,
    PasswordInput,
} from "react-admin";

const selectChoices = [
    {id: "ROLE_DOMAIN_ADMIN", name: "Administrador de dominio"},
    {id: "ROLE_ADMIN", name: "Administrador"},
];


const WalletAddress = () => {
    const {setValue, getValues} = useFormContext();

    const getNewWallet = () => fetch(`${process.env.REACT_APP_BLOCKCHAIN_API_URL}/wallet/generate`)
        .then((response) => response.json())
        .then((res) => {
            setValue("walletAddress", res.data.walletAddress, { shouldDirty: true });
            setValue("privateKey", res.data.privateKey, { shouldDirty: true });
        })

    if (!getValues("walletAddress") && !getValues("privateKey")) {
        getNewWallet();
    }

    return (
        <>
            <Button variant="contained" onClick={getNewWallet}>
                Get new wallet
            </Button>
            <InputGuesser source={"walletAddress"}/>
            <PasswordInput source={"privateKey"}/>
        </>
    );
};

export const UserCreate = (props) => {

    return (
        <CreateGuesser {...props}>
            <InputGuesser source={"name"}/>
            <InputGuesser source={"surname"}/>
            <WalletAddress/>
            <InputGuesser source={"phone"}/>
            <PasswordInput source={"plainPassword"}/>
            <InputGuesser source={"birthDate"}/>
            <InputGuesser source={"isVisible"}/>
            {/* <InputGuesser source={"password"} /> */}
            <SelectArrayInput
                source={"roles"}
                choices={selectChoices}
                parse={(v) => v}
                format={(v) => v}
            />
        </CreateGuesser>
    );
}


export const UserEdit = (props) => {
    const {permissions} = usePermissions();
    console.log(permissions);
    return (
        <EditGuesser {...props}>
            <InputGuesser source={"name"}/>
            <InputGuesser source={"surname"}/>
            <WalletAddress/>
            <InputGuesser source={"phone"}/>
            <PasswordInput source={"plainPassword"}/>
            <InputGuesser source={"birthDate"}/>
            <InputGuesser source={"isVisible"}/>
            {/* <InputGuesser source={"password"} /> */}
            <SelectArrayInput
                source={"roles"}
                choices={selectChoices}
                parse={(v) => v}
                format={(v) => v}
            />
            <ReferenceArrayInput source="avatar_ids" reference="avatars">
                <SelectArrayInput optionText="nick"/>
            </ReferenceArrayInput>
        </EditGuesser>
    );
};

export const UserList = (props) => (
    <ListGuesser {...props}>
        <FieldGuesser source={"name"}/>
        <FieldGuesser source={"surname"}/>
        <FieldGuesser source={"walletAddress"}/>
        <FieldGuesser source={"phone"}/>
        <FieldGuesser source={"birthDate"}/>
        <FieldGuesser source={"isVisible"}/>
    </ListGuesser>
);

export const UserShow = (props) => {

    return (
        <ShowGuesser>
            <FieldGuesser source={"name"}/>
            <FieldGuesser source={"surname"}/>
            <FieldGuesser source={"walletAddress"}/>
            <FieldGuesser source={"phone"}/>
            <FieldGuesser source={"birthDate"}/>
            <FieldGuesser source={"isVisible"}/>
            <ArrayField label="Avatars" source="avatars">
                <Datagrid bulkActionButtons={false}>
                    <TextField source="nick"/>
                    <TextField source="bio"/>
                    <TextField source="facebook"/>
                    <TextField source="twitter"/>
                    <TextField source="instagram"/>
                    <ImageField source="image" title="title"/>
                </Datagrid>
            </ArrayField>
        </ShowGuesser>
    );
};
