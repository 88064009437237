import React from "react";
import {
  CreateGuesser,
  EditGuesser,
  InputGuesser,
  ListGuesser,
  FieldGuesser,
} from "@api-platform/admin";
import {
  ImageInput,
  ImageField,
  SelectInput,
  regex,
  ReferenceInput,
  AutocompleteInput,
  TextInput,
  useRecordContext,
} from "react-admin";
import { ColorInput } from "react-admin-color-picker";
import { useController, useWatch } from "react-hook-form";
import Button from "@mui/material/Button";

const servicesFilters = [<TextInput label="Search domain" source="domain.name" alwaysOn />, <TextInput label="Search service" source="name" alwaysOn />];

const StyleChoices = [
  { id: "btn-sm", name: "Small Button" },
  { id: "btn-md", name: "Medium Button" },
  { id: "btn-lg", name: "Large Button" },
  { id: "label", name: "Label" },
];

const colorRegex = /^#[0-9a-f]{6}/i;
const colorMsg = "Color not valid";

const UploadedImage = () => {
  const record = useRecordContext();
  const image = useController({ name: "mediaObject" });
  const watchImage = useWatch({ name: "mediaObject" });

  return !!record.image && watchImage !== null ? (
    <>
      <ImageField source="image" title="title" />
      <Button onClick={() => image.field.onChange(null)}>Remove image</Button>
    </>
  ) : null;
};

export const ServiceCreate = (props) => (
  <CreateGuesser {...props}>
    <InputGuesser source={"name"} />
    <InputGuesser source={"url"} />
    <ReferenceInput source="domain" reference="domains" label="Domain">
      <AutocompleteInput
        optionText="name"
        filterToQuery={(searchText) => ({ searchtext: searchText })}
      />
    </ReferenceInput>
    <InputGuesser source={"position"} />
    <ColorInput
      source="fgcolor"
      validate={regex(colorRegex, colorMsg)}
      label={"Text color"}
    />
    <ColorInput
      source="bgcolor"
      validate={regex(colorRegex, colorMsg)}
      label={"Background color"}
    />
    <SelectInput source={"style"} choices={StyleChoices} />

    <ImageField source="image" title="title" />
    <ImageInput source="mediaObject" accept="image/*">
      <ImageField source="image" title="title" />
    </ImageInput>
  </CreateGuesser>
);

export const ServiceEdit = (props) => (
  <EditGuesser {...props}>
    <InputGuesser source={"name"} />
    <InputGuesser source={"url"} />
    <ReferenceInput source="domain" reference="domains" label="Domain">
      <AutocompleteInput
        optionText="name"
        filterToQuery={(searchText) => ({ searchtext: searchText })}
      />
    </ReferenceInput>
    <InputGuesser source={"position"} />
    <ColorInput
      source="fgcolor"
      validate={regex(colorRegex, colorMsg)}
      label={"Text color"}
    />
    <ColorInput
      source="bgcolor"
      validate={regex(colorRegex, colorMsg)}
      label={"Background color"}
    />
    <SelectInput source={"style"} choices={StyleChoices} />
    <UploadedImage />
    <ImageInput source="mediaObject" accept="image/*">
      <ImageField source="image" title="title" />
    </ImageInput>
  </EditGuesser>
);

export const ServiceList = (props) => (
  <ListGuesser {...props} filters={servicesFilters}>
    <FieldGuesser source={"name"} />
    <FieldGuesser source={"url"} />
    <FieldGuesser source={"domain"} />
    <ImageField source="image" title="title" />
  </ListGuesser>
);
