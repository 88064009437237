import { defaultTheme } from 'react-admin';
import merge from 'lodash/merge';

const customTheme = merge({}, defaultTheme, {
    palette: {
      primary: {
        main: '#4B6EF5'
       },
      secondary: {
        main: '#4B6EF5'
      }
    },
  })

export default customTheme;