import React from 'react';
import {
  AppBar,
  AppBarClasses,
  ToggleThemeButton,
  useAuthProvider,
  Layout
} from 'react-admin';
import { Box, Typography } from '@mui/material';

// import Logo from './Logo';
// import { darkTheme, lightTheme } from './themes';

const CustomAppBar = (props) => (
    <AppBar >
      <Typography
        variant="h6"
        color="inherit"
        className={AppBarClasses.title}
        id="react-admin-title"
      />
      {/* <Logo /> */}
      <img id="logo" src='logo.png'></img>
      <Box component="span" sx={{ flex: 1 }} />
      {/* <ToggleThemeButton lightTheme={lightTheme} darkTheme={darkTheme} /> */}
    </AppBar>
)

const CustomLayout = (props) => (
  <Layout appBar={CustomAppBar} {...props} />
);

export default CustomLayout;