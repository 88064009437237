import React, { useEffect, useRef, useState } from "react";
import mapboxgl from "mapbox-gl";

import { useFormContext } from "react-hook-form";
import { useEditContext } from "react-admin";

import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import "mapbox-gl/dist/mapbox-gl.css";

import {
    Typography} from "@mui/material";
mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_API_TOKEN;

export const MapFieldAp = (props) => {
    const { record, isLoading } = useEditContext();
    const { setValue, getValues } = useFormContext();
    const [mounted, setMounted] = useState(false);

    const mapContainerRef = useRef(null);
    const marker = new mapboxgl.Marker();

    useEffect(() => {
        console.log({ record });
        if (mounted) {
            return;
        }
        const center = JSON.parse(props.domain.centroid).coordinates;
        const map = new mapboxgl.Map({
            container: mapContainerRef.current,
            style: "mapbox://styles/mapbox/streets-v11",
            center,
            zoom: 18,
        });

        map.on("load", function () {
            const geometry = JSON.parse(props.domain.geometry);
            // Add an image to use as a custom marker
            map.addSource("domain", {
                type: "geojson",
                data: {
                    type: 'Feature',
                    geometry,
                },
            });
            map.addLayer({
                'id': 'domain',
                'type': 'fill',
                'source': 'domain', // reference the data source
                'layout': {},
                'paint': {
                    'fill-color': '#0080ff', // blue color fill
                    'fill-opacity': 0.2
                }
            });
            // Add a black outline around the polygon.
            map.addLayer({
                'id': 'outline',
                'type': 'line',
                'source': 'domain',
                'layout': {},
                'paint': {
                    'line-color': '#000',
                    'line-width': 1
                }
            });
            if (record) {
                const { coordinates } = JSON.parse(record.geolocation);
                marker.setLngLat(coordinates).addTo(map);
            }
        });



        // When a click event occurs on a feature in the states layer,
        // open a popup at the location of the click, with description
        // HTML from the click event's properties.
        map.on('click', 'domain', (e) => {
            // new mapboxgl.Popup()
            //     .setLngLat(e.lngLat)
            //     .setHTML(e.lngLat.toArray())
            //     .addTo(map);
            const coordinates = e.lngLat;
            marker.setLngLat(coordinates).addTo(map);
            const data = {
                type: 'Point',
                coordinates: coordinates.toArray(),
            }
            setValue('geolocation', JSON.stringify(data));
            setValue('domain', `/api/domains/${props.domain.id}`);
        });

        // Change the cursor to a pointer when
        // the mouse is over the states layer.
        map.on('mouseenter', 'domain', () => {
            map.getCanvas().style.cursor = 'pointer';
        });

        // Change the cursor back to a pointer
        // when it leaves the states layer.
        map.on('mouseleave', 'domain', () => {
            map.getCanvas().style.cursor = '';
        });

        // Add navigation control (the +/- zoom buttons)
        map.addControl(new mapboxgl.NavigationControl(), "top-right");
        setMounted(true);

        // Clean up on unmount
        // return () => map.remove();

    }, [mounted])

    return <>
        <Typography component="div">
            Selecciona la ubicación del punto de acceso:
        </Typography>
        <div ref={mapContainerRef} style={props.style}></div>
    </>
}